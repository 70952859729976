#onetrust-consent-sdk {
  #onetrust-pc-sdk {
    .pc-logo-container {
      display: none;
    }
    .pc-title-container {
      margin: 0;
      text-transform: uppercase;
      width: 100%;
      #pc-title {
        margin: 10px 0;
        text-align: center;
        width: 100%;
        &::before,
        &::after {
          display: none;
        }
      }
      .pc-close-btn-container {
        margin-top: 0;
      }
    }
    .ot-checkbox {
      input[type='checkbox'] {
        &:checked ~ label::before,
        & + label::after {
          -webkit-mask: none;
          mask: none;
        }
        & + label::after {
          background: none;
          border-#{$rdirection}: 0;
        }
      }
    }
    .ot-toggle .checkbox {
      input[type='checkbox'] {
        & + label::after {
          -webkit-mask: none;
          mask: none;
        }
      }
    }
    .ot-close-icon {
      background-size: 100%;
      height: 22px;
      width: 22px;
    }
    .ot-group-option {
      display: block;
    }
  }
}
#onetrust-banner-sdk {
  #onetrust-close-btn-container {
    .ot-close-icon {
      background-color: $color-white;
      height: 22px;
      width: 22px;
      &:hover {
        background-size: 12px !important;
      }
    }
  }
}
